import { useState } from "react"
import styled from "styled-components"

import { endOfDay, subMonths } from "date-fns"

import {
  GraphTitle,
  IGraphDateRange,
} from "src/components/Homes/DeviceDetails/Overview/DeviceGraphs"
import { GraphCard } from "src/components/Homes/DeviceDetails/Overview/GraphCard"
import { useGetGraphData } from "src/components/Homes/DeviceDetails/Overview/useGetGraphData"
import {
  HREF_MINUT_HELP_DECREASE_MOLD_RISK,
  HREF_MINUT_HELP_MOLD_RISK,
} from "src/constants/hrefs"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { THome } from "src/data/homes/types/homeTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import { DateRangePicker } from "src/ui/DateRangePicker/DateRangePicker"
import { ChartTooltip } from "src/ui/Graphs/ChartTooltip"
import { LineChartCardProps } from "src/ui/Graphs/graphTypes"
import { LineChart } from "src/ui/Graphs/LineChart"
import { MInfo } from "src/ui/Info/MInfo"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"

type MoldRiskGraphCardProps = Omit<
  LineChartCardProps,
  "data" | "loading" | "hidden" | "timezone"
>

export function MoldRiskGraphCard({
  hideGraphBorder,
  device,
  home,
}: MoldRiskGraphCardProps & { device: TDevice; home: THome }) {
  const user = useGetUser()
  const { t, langKeys } = useTranslate()

  const [dateRange, setDateRange] = useState(defaultDateRange)

  const { mouldRisk } = useGetGraphData({ dateRange, device, home })

  // This dynamically determines tick positions for the mould risk scale:
  // - Default scale is 0-3 to represent low to moderate risk.
  // - If the highest mould risk value exceeds 3, the scale expands to
  // include up to that value + 1 to ensure that the scale is adaptive, showing only relevant risk levels.
  function getTickPositions() {
    const highRiskIndex = 3
    const tickPositions = [0, 1, 2, 3, 4, 5, 6]

    const maxMouldRiskValue = Math.max(
      ...(mouldRisk.data?.map((d) => d.value || 0) || [])
    )

    // Will check maxMouldRiskValue against 2.5 so that there is reasonable space between the top of the
    // graph and the top of the scale for values below 3.
    const threshold =
      maxMouldRiskValue <= 2.5
        ? highRiskIndex
        : Math.round(maxMouldRiskValue) + 1

    return tickPositions.filter((tick) => tick <= threshold)
  }

  function getMoldRiskLevel(value: number) {
    if (value < 1) {
      return t(langKeys.risk_of_mold_low)
    }
    if (value < 2) {
      return t(langKeys.risk_of_mold_medium)
    }
    return t(langKeys.risk_of_mold_high)
  }

  return (
    <GraphCard
      title={
        <MoldRiskTitle dateRange={dateRange} setDateRange={setDateRange} />
      }
      isLoading={mouldRisk.isLoading}
      hideGraphBorder={hideGraphBorder}
    >
      {mouldRisk.data && (
        <LineChart
          data={mouldRisk.data}
          tooltip={{
            formatter: ({ date, value }) => (
              <ChartTooltip
                label={t(langKeys.mold_risk_level)}
                value={Number(value.toFixed(1))}
                unit={`(${getMoldRiskLevel(value)})`}
                date={formatDate({
                  date: date.toISOString(),
                  clockType: user.clock_type,
                  timezone: home.timezone,
                })}
              />
            ),
          }}
          timezone={home.timezone}
          clockType={user.clock_type}
          step="center"
          smooth={false}
          xAxisOptions={{
            min: dateRange.startDate.getTime(),
            max: dateRange.endDate.getTime(),
          }}
          yAxisOptions={{
            tickInterval: 0.5,
            tickPositions: [...getTickPositions()],
            labels: {
              style: {
                fontFamily: "'Figtree', sans-serif",
                fontSize: "12px",
                color: mColors.textTertiary,
              },
            },
            plotBands: [
              {
                from: 0,
                to: 1,
                color: `${mColors.systemGoodLight}50`,
                label: {
                  text: t(langKeys.risk_of_mold_low),
                  style: {
                    fontFamily: "'Figtree', sans-serif",
                    fontSize: "12px",
                    color: mColors.textTertiary,
                  },
                },
              },
              {
                from: 1,
                to: 2,
                color: `${mColors.systemWarningLight}50`,
                label: {
                  text: t(langKeys.risk_of_mold_medium),
                  style: {
                    fontFamily: "'Figtree', sans-serif",
                    fontSize: "12px",
                    color: mColors.textTertiary,
                  },
                },
              },
              {
                from: 2,
                to: 6,
                color: `${mColors.systemErrorLight}50`,
                label: {
                  text: t(langKeys.risk_of_mold_high),
                  style: {
                    fontFamily: "'Figtree', sans-serif",
                    fontSize: "12px",
                    color: mColors.textTertiary,
                  },
                },
              },
            ],
          }}
        />
      )}
    </GraphCard>
  )
}

function defaultDateRange(): IGraphDateRange {
  return { startDate: subMonths(new Date(), 3), endDate: new Date() }
}

function MoldRiskTitle({
  dateRange,
  setDateRange,
}: {
  dateRange: IGraphDateRange
  setDateRange: (dateRange: IGraphDateRange) => void
}) {
  const { t, langKeys } = useTranslate()
  const title = t(langKeys.risk_of_mold)

  function handleDateRangeChange({ startDate, endDate }: IGraphDateRange) {
    setDateRange({ startDate, endDate: endOfDay(endDate) })
  }

  return (
    <MoldRiksTitleBox>
      <GraphTitle>
        {title}
        <MInfo
          interactive={true}
          content={
            <TooltipContent>
              <MText variant="bodyS">
                {t(langKeys.mold_risk_graph_tooltip)}
              </MText>

              <LinkList>
                <li>
                  <ExternalLink
                    href={HREF_MINUT_HELP_DECREASE_MOLD_RISK}
                    showExternalIcon={false}
                  >
                    {t(langKeys.mold_risk_article_how_to_decrese_mold_risk)}
                  </ExternalLink>
                </li>

                <li>
                  <ExternalLink
                    href={HREF_MINUT_HELP_MOLD_RISK}
                    showExternalIcon={false}
                  >
                    {t(langKeys.mold_risk_article_how_we_detect_mold_risk)}
                  </ExternalLink>
                </li>
              </LinkList>
            </TooltipContent>
          }
        />
      </GraphTitle>

      <DateRangePicker
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        onDateRangeChange={handleDateRangeChange}
        dateRangeProps={{ minBookingDays: 30 }}
        onSelectPreset={({ clearSelection }) => {
          if (clearSelection) {
            setDateRange(defaultDateRange())
          }
        }}
        calendarPlacement="top-end"
        hidePresets={["DAY", "WEEK"]}
      />
    </MoldRiksTitleBox>
  )
}

const MoldRiksTitleBox = styled.div`
  display: flex;
  gap: ${spacing.M};
  align-items: center;
  justify-content: space-between;
`

const LinkList = styled.ul`
  padding: 0;
  margin: 0 1rem;
`

const TooltipContent = styled.div`
  display: grid;
  gap: ${spacing.M};
`
